import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Dumbbell Jumping Lunges 4×4/leg`}</p>
    <p>{`Weighted Hip (Shoulder) Bridges 4×4/leg`}</p>
    <p>{`then,`}</p>
    <p>{`12-Back Squats (185/125)(RX+ 225/155)`}</p>
    <p>{`10-Double Unders`}</p>
    <p>{`10-Back Squats`}</p>
    <p>{`20-Double Unders`}</p>
    <p>{`8-Back Squats`}</p>
    <p>{`30-Double Unders`}</p>
    <p>{`6-Back Squats`}</p>
    <p>{`40-Double Unders`}</p>
    <p>{`4-Back Squats`}</p>
    <p>{`50-Double Unders`}</p>
    <p>{`2-Back Squats`}</p>
    <p>{`60-Double Unders`}</p>
    <p>{`for time.`}</p>
    <p><em parentName="p">{`*`}{`compare to 2/3/20`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      